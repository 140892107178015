import React from 'react'
import { Link, withPrefix, graphql } from 'gatsby'
import Layout from '../components/layouts/layout'
import ProgressBar from '../components/layouts/progressbar'
import HeadMeta from '../components/layouts/headmeta'
import Content, { HTMLContent } from '../components/layouts/content'
import { changeProgressBarOnScroll } from '../components/utils/index'

export default class SupportPage extends React.Component {
  componentDidMount() {
    window.addEventListener('scroll', function() {
      changeProgressBarOnScroll()
    });
    window.analytics.page('Support', this.props.data.markdownRemark.frontmatter.title)
  }

  render() {
    const { markdownRemark: post } = this.props.data
    const {
      metadata,
      title,
      foldertitle,
      categorytitle
    } = post.frontmatter
    const contentComponent = HTMLContent
    const content = post.html
    const PostContent = contentComponent || Content

    return (
      <Layout>
        <div className="blog-post">
          <HeadMeta metadata={metadata}/>
          <ProgressBar />
          <section className="post-content bg-light">
            <div className="container content bg-light">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item active" aria-current="page"><Link to={withPrefix("/support")}>Support</Link></li>
                  <li className="breadcrumb-item active" aria-current="page">{foldertitle}</li>
                  <li className="breadcrumb-item active" aria-current="page">{categorytitle}</li>
                </ol>
              </nav>
              <h1>{title}</h1>
              <hr />
              <div className="columns mt-5">
                <div className="column is-10 is-offset-1">
                  <PostContent content={content} />
                </div>
              </div>
              <hr/>
              <section className="text-center">
                <h3>Still have questions?</h3>
                <a className="btn" href="mailto:hello@advanxhealth.com"><i className="fa fa-envelope"></i> Send Us An Email</a>
                <a className="btn" href="https://www.facebook.com/advanxhealthmy"><i className="fa fa-facebook"></i> Chat With Us On Facebook</a>
              </section>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export const supportPageQuery = graphql`
  query SupportPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      id
      html
      frontmatter {
        title
        metadata {
          title
          description
          url
          image
        }
        foldertitle
        categorytitle
      }
    }
  }
`
